import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { CheckCircleIcon } from 'lucide-react';

export const HelpCenter = () => {
  const list = ['24/7 customer support', 'Quick response time', 'Dedicated team for your queries'];

  return (
    <div className="flex flex-col items-center gap-4">
      <Typography variant="headerMedium">Got questions?</Typography>
      {list.map((item, index) => (
        <div className="flex w-full items-start gap-4" key={index}>
          <CheckCircleIcon className="text-foreground mt-px h-6 w-6" />
          <Typography variant="bodyLarge" className="w-full">
            {item}
          </Typography>
        </div>
      ))}
      <Button buttonType={'secondary'} text="Contact support" />
    </div>
  );
};
