import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { cloneElement } from 'react';

interface SingleAddressProps {
  addressName: string;
  addressString: string;
  icon: JSX.Element;
  className?: string;
  addressLineClassName?: string;
}
export const SingleAddress = (props: SingleAddressProps) => {
  const { addressName, addressString, icon, className, addressLineClassName } = props;

  const iconComponent = cloneElement(icon, { className: 'w-4 h-4' });
  return (
    <div className={cn('flex w-full flex-row gap-4 rounded-lg border border-slate-200 bg-slate-50 p-4', className)}>
      <div className="mt-1">{iconComponent}</div>
      <div className="flex flex-col items-start gap-2">
        <Typography variant="body" className="flex flex-row items-center gap-2">
          {addressName}
        </Typography>
        <Typography variant="bodySmall" className={cn('text-slate-600', addressLineClassName)}>
          {addressString}
        </Typography>
      </div>
    </div>
  );
};
