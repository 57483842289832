import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { useIsMobile } from '@pesto/hubble-shared/hooks/useIsMobile';
import clsx from 'clsx';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';

interface OtherReviewProps {
  className?: string;
}
export const OtherReview = (props: OtherReviewProps) => {
  const { className } = props;
  const isMobile = useIsMobile();
  const { gotoHome } = useGoRoutes();

  const buttonText = 'Continue';

  const rootStyle = clsx(
    'flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 md:h-[76vh] justify-center items-center',
    className,
  );
  return (
    <Wrapper1280 className={rootStyle}>
      <Typography variant={isMobile ? 'headerMedium' : 'heroSmall'}>We are reviewing your item</Typography>
      <Typography variant={'headerSmall'}>One of our professionals is reviewing your item.</Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Once we have reviewed your item, you will receive an email to continue the credit process.
      </Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Thank you!
      </Typography>
      <ButtonContainer rightButton={<Button onClick={gotoHome} buttonType={'primary'} text={buttonText} />} />
    </Wrapper1280>
  );
};
