import { LoginPageLayoutCentered } from '../layouts/LoginPageLayoutCentered';
import { EmailSignInScreen } from './components/EmailSignInScreen';

export const EmailLoginPage = () => {
  return (
    <>
      <LoginPageLayoutCentered>
        <EmailSignInScreen
          hideSignUpButton
          title="Confirm email"
          description="We will send you a code to confirm your email"
        />
      </LoginPageLayoutCentered>
    </>
  );
};
