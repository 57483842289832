import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { TextField } from '@pesto/hubble-shared/components/UI/Form/TextField/TextField';
import type { FormEvent } from 'react';

interface PhoneEnterFormProps {
  register: any;
  email: string;
  setValue: any;
  errors: any;
  onSubmit: () => void;
  codeLoading?: boolean;
}

export const EmailEnterForm = (props: PhoneEnterFormProps) => {
  const { onSubmit, register, email, setValue, errors, codeLoading } = props;

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={submitHandler}>
      <TextField
        data-testid="email-field"
        {...register('email')}
        name="email"
        type="email"
        label="Email"
        value={email}
        placeholder="email@email.com"
        onChange={event => setValue('email', event.target.value)}
        error={!!errors.email}
        errorText={errors.email?.message}
        required
      />
      <Button
        className="bg-black text-white"
        data-testid="sign-in-button-email"
        buttonType="primary"
        text={'Send 6-digit code'}
        isLoading={codeLoading}
        disabled={codeLoading}
      />
    </form>
  );
};
