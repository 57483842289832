import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';

import { MAX_CREDIT_LINE } from '../../../../../constants/application';
import { useIncreaseCredit } from '../../../../../hooks/useIncreaseCredit';

import Congratulations from './components/Congratulations';

const Congrats = () => {
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const referenceNumber = user?.cards?.[0]?.referenceId;
  const referenceNumberValue = referenceNumber ? String(referenceNumber) : undefined;
  const creditLimit = user?.cards?.[0]?.creditLimit;

  const showIncreaseCredit = (creditLimit || 0) < MAX_CREDIT_LINE && !!user?.canStartNewApplication;

  const { loading, handleIncreaseClick } = useIncreaseCredit(user);
  if (loading) return <LoaderSpinnerFull variant={'screen'} />;

  return (
    <Congratulations
      referenceNumber={referenceNumberValue}
      onIncreaseClick={handleIncreaseClick}
      itemName={'cash'}
      creditLimit={creditLimit}
      showIncreaseCredit={showIncreaseCredit}
    />
  );
};

export default Congrats;
