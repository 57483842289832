import type { ReactNode } from 'react';

import { routes } from '../../router/routes';

import { HeaderWrapper } from './HeaderWrapper';
import Navigation from './components/Navigation';

interface HeaderTypeProps {
  className?: string;
  creditEstimate: ReactNode;
  stepper: ReactNode;
  divider?: boolean;
}

export const Header = ({ className, creditEstimate, stepper, divider = false }: Partial<HeaderTypeProps>) => {
  return (
    <>
      <HeaderWrapper
        className={className}
        divider={divider}
        afterHeader={
          (creditEstimate || stepper) && (
            <div className="border-neutralLight bg-offWhite flex flex-col gap-2 border-b py-2">
              {stepper && <div className="relative flex items-center justify-center">{stepper}</div>}
              {creditEstimate && creditEstimate}
            </div>
          )
        }
      >
        <Navigation logoLink={routes.app} />
      </HeaderWrapper>
    </>
  );
};
