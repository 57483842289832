import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { useIsMobile } from '@pesto/hubble-shared/hooks/useIsMobile';

interface CancelOfferProps {
  onReject: (accepted: boolean) => void;
  onBack: () => void;
}

export const CancelOffer = (props: CancelOfferProps) => {
  const { onReject, onBack } = props;
  const isMobile = useIsMobile();
  return (
    <>
      <div className="flex w-full max-w-[500px] flex-col items-center gap-4 text-left">
        <div className="text-neutral text-center">
          <Typography variant={isMobile ? 'headerSmall' : 'headerMedium'} className={'text-neutral'}>
            Are you sure you want to reject the offer?
          </Typography>
          <Typography variant={'bodySmall'} className={'text-neutral'}>
            Once your item has been shipped back to you, the offer will be canceled. If you’d change your mind, you will
            have to send us your item again.
          </Typography>
        </div>
      </div>
      <ButtonContainer
        column
        rightButton={<Button onClick={onBack} buttonType="primary" text={'BACK TO OFFER'} />}
        leftButton={
          <Button
            buttonType="secondary"
            onClick={() => onReject(false)}
            text={'I’m sure, reject offer and send item back'}
          />
        }
      />
    </>
  );
};
