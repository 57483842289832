import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgInfo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx={12} cy={12} r={8.25} stroke="#4B5157" strokeWidth={1.5} />
    <path
      fill="#4B5157"
      d="M11.09 17v-6.546h1.815V17zm.912-7.39a.99.99 0 0 1-.695-.268.87.87 0 0 1-.286-.652q0-.375.286-.643a.98.98 0 0 1 .695-.273q.405 0 .69.273.29.268.29.643 0 .38-.29.652a.97.97 0 0 1-.69.269"
    />
  </svg>
);
export default SvgInfo;
