import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import Svg404Icon from '@pesto/hubble-shared/icons/components/404Icon';
import { cn } from '@pesto/hubble-shared/utils/cn';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';

const InvalidState = () => {
  const rootStyle = cn('flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 h-[80vh] justify-between items-center');
  const { gotoHome } = useGoRoutes();

  console.warn('Invalid State Page shown');
  return (
    <Wrapper1280 className={rootStyle}>
      <div className="flex flex-col items-center gap-4">
        <Typography variant="headerLarge">Oops!</Typography>

        <div className="mb-[24px] mt-[24px] flex justify-center">
          <Svg404Icon className="h-[240px] w-[240px]" />
        </div>

        <div>
          <Typography variant="headerMedium" className="text-center">
            Sorry, something happened
          </Typography>

          <Typography variant="body" className="mt-2 text-center text-2xl">
            It looks like we encountered an error. <br />
            Try again later.
          </Typography>
        </div>
      </div>

      <Button buttonType="secondary" onClick={gotoHome} text="Return to the homepage" className="w-full" />
    </Wrapper1280>
  );
};

export default InvalidState;
