import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { phoneFormat } from '@pesto/hubble-shared/utils/phonehelper';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { PestoCode } from '../../../components/PestoCode/PestoCode';
import TextLink, { LinkType } from '../../../components/TextLink';

interface EnterOtpFormProps {
  type: 'phone' | 'email';
  buttonText?: string;
  phoneOrEmail: string;
  error: {
    sentCodeError: string;
    verifyCodeError: string;
  };
  sendCode: (phoneNumber: string) => Promise<unknown>;
  onEdit: () => void;
  onSubmit: (code: string) => void;
}

export const EnterOtpForm = (props: EnterOtpFormProps) => {
  const { type, buttonText = 'Sign In', phoneOrEmail, onEdit, sendCode, error, onSubmit } = props;
  const [code, setCode] = useState('');
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(false);
  const [resendCodeTimer, setResendCodeTimer] = useState(20);

  const yourPhoneOrEmail = type === 'phone' ? 'phone' : 'email';
  const handleSubmit = () => {
    onSubmit(code);
  };
  const handleResendCode = () => {
    setIsResendCodeDisabled(true);
    setResendCodeTimer(20);

    sendCode(phoneOrEmail)
      .then(() => {
        toast('Code has been sent successfully!');
      })
      .catch(error => {
        toast.error('An error occurred: ' + error.message);
      });

    const timer = setInterval(() => {
      setResendCodeTimer(prevTimer => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendCodeDisabled(false);
      clearInterval(timer);
    }, 20000);
  };

  const phoneOrEmailRender = type === 'phone' ? phoneFormat(phoneOrEmail) : phoneOrEmail;

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <Typography variant={'body'} className={'text-neutral'}>
          We sent a code to your {yourPhoneOrEmail}
        </Typography>
        <Typography variant={'bodyLarge'} className={'!font-bold'}>
          {phoneOrEmailRender}
        </Typography>
        <Typography variant={'body'} className={'text-neutral'}>
          Please enter the code sent to your {yourPhoneOrEmail} below.{' '}
        </Typography>
        <Typography variant={'body'}>
          Made a mistake?{' '}
          <TextLink as={LinkType.Button} onClick={onEdit} className={'w-auto'}>
            <Typography variant={'body'}>Edit {yourPhoneOrEmail}</Typography>
          </TextLink>
        </Typography>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <PestoCode
          onChange={code => {
            setCode(code);
          }}
          error={!!error.verifyCodeError}
        />
        <div>
          <Typography variant={'bodySmall'} className={'text-neutral'}>
            Didn’t get your one-time password?{' '}
            <TextLink as={LinkType.Button} className={'w-auto'} onClick={handleResendCode} type={'button'}>
              <Typography variant={'bodySmall'}>Resend code</Typography>
            </TextLink>
          </Typography>

          {isResendCodeDisabled && (
            <Typography variant={'bodySmall'} className={'text-neutral mt-[10px] text-center'}>
              Next resend in <b>{`${resendCodeTimer}s`}</b>
            </Typography>
          )}
        </div>
        <Button
          className="bg-black text-white"
          type={'submit'}
          data-testid="sign-in-button-email"
          buttonType="primary"
          onClick={handleSubmit}
          text={buttonText}
        />
      </form>
    </>
  );
};
