import { cn } from '../../../utils/cn';

type GenerateRootStyleProps = {
  className?: string;
  isChecked?: boolean;
  size?: { width: string; height: string };
  variant?: 'default' | 'checker';
};

export const generateRootStyle = ({
  className = '',
  isChecked,
  size = { width: '20px', height: '20px' },
  variant = 'default',
}: GenerateRootStyleProps): string => {
  const baseStyles = cn(
    className,
    'h-7 w-7 p-[4px] rounded-full border cursor-pointer flex items-center justify-center transition ease-in-out duration-300',
  );

  const checkedStyles = cn({
    'bg-basic border-neutral shadow-md hover:bg-neutralStrong active:bg-basicDarker':
      variant === 'default' && isChecked,
    'bg-white border-neutralStrong hover:bg-gray-200 active:bg-gray-300': variant === 'default' && !isChecked,
    'bg-basic border-neutral shadow-md': variant === 'checker' && isChecked,
    'bg-white border-neutralStrong': variant === 'checker' && !isChecked,
  });

  const sizeStyles = cn({
    [`w-${size.width} h-${size.height}`]: !!size.width && !!size.height,
  });

  return `${baseStyles} ${checkedStyles} ${sizeStyles}`;
};
