import type { ApolloClientConfig } from '@pesto/hubble-shared/api/Apollo';
import { ApolloProviderWrapper } from '@pesto/hubble-shared/api/Apollo';
import { AuthProvider } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import type { AuthConfig } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider.types';

import './App.css';
import { ReactAppEnv, ReactEnv } from './constants/global';
import ErrorBoundary from './providers/ErrorBoundary';
import { UserProvider } from './providers/UserProvider';
import RouterProvider from './router/RouterProvider';

const apolloConfig: ApolloClientConfig = {
  directUri: import.meta.env.VITE_APP_URI ?? '',
  proxyUri: import.meta.env.VITE_VGS_INBOUND_URI ?? '',
  path: import.meta.env.VITE_GQL_PATH ?? '',
};

const authConfig: AuthConfig = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN ?? '',
  clientID: import.meta.env.VITE_AUTH0_CLIENT_ID ?? '',
  audience: import.meta.env.VITE_AUTH0_AUDIENCE ?? '',
  redirectUri: import.meta.env.VITE_AUTH0_REDIRECT_URI + '/login-callback' || '',
};

const isDev = ReactAppEnv !== ReactEnv.PRODUCTION;

function App() {
  return (
    <>
      <ApolloProviderWrapper config={apolloConfig} isDev={isDev}>
        <AuthProvider config={authConfig}>
          <UserProvider>
            <ErrorBoundary>
              <RouterProvider />
            </ErrorBoundary>
          </UserProvider>
        </AuthProvider>
      </ApolloProviderWrapper>
    </>
  );
}

export default App;
