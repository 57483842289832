import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { cloneElement } from 'react';

import { Fragment } from 'react/jsx-runtime';

export interface StepProps {
  title: string;
  description: string;
  icon: JSX.Element;
  isActive?: boolean;
  isCompleted?: boolean;
}

const Step = (props: StepProps) => {
  const { title, description, icon, isActive, isCompleted } = props;

  return (
    <div className="flex items-start gap-4">
      <div
        className={cn('rounded-lg border border-slate-300 p-4', {
          'bg-foreground': isActive,
          'bg-foreground/10': isCompleted,
        })}
      >
        {cloneElement(icon, {
          className: cn('h-6 w-6', {
            'text-background': isActive,
          }),
        })}
      </div>
      <div className="flex flex-col">
        <Typography
          variant="headerSmall"
          className={cn({
            'text-foreground': isActive,
            'text-black': isCompleted,
            'text-black/30': !isActive && !isCompleted,
          })}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyLarge"
          className={cn('text-slate-600', {
            'text-slate-600': isActive,
            'text-slate-600/30': !isActive && !isCompleted,
          })}
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};

export interface StepperComponentProps {
  steps: StepProps[];
  activeStep: number;
}

export const StepperComponent = (props: StepperComponentProps) => {
  const { steps, activeStep } = props;

  return (
    <div className="flex flex-col items-start justify-center">
      {steps.map((step, index) => (
        <Fragment key={index}>
          <Step {...step} isActive={index === activeStep} isCompleted={index < activeStep} />
          {index < steps.length - 1 && <div className="ml-7 w-px flex-1 bg-slate-300 pb-4" />}
        </Fragment>
      ))}
    </div>
  );
};
