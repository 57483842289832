import type { CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';

import { appRoutes, statusRoutes } from './../../../src/router/routes';

export type StatusHomelightHandlers = Record<CreditApplicationStatus, () => string>;

export const statusHomelightHandlers: StatusHomelightHandlers = {
  OFFER_DECISION_NEEDED: () => appRoutes.personalInfo,
  COMPLETE: () => appRoutes.congratulations,
  OFFER_REJECTED: () => statusRoutes.offerRejected,

  CREATED: () => appRoutes.personalInfo,
  APPRAISAL_IN_PROGRESS: () => appRoutes.personalInfo,
  PREQUAL_ITEM_VALUED: () => appRoutes.personalInfo,
  FIN_INFO_NEEDED: () => appRoutes.personalInfo,
  PREQUALIFIED: () => appRoutes.personalInfo,
  OFFER_ACCEPTED: () => appRoutes.agreements,
  NOT_PREQUALIFIED: () => statusRoutes.notPrequalified,
  MANUAL_REVIEW: () => statusRoutes.manualReview,
  CREDIT_DENIED: () => statusRoutes.creditDenied,
  ITEM_UNDER_REVIEW: () => statusRoutes.inReview,
  INVALID_STATE: () => appRoutes.offer,
  CREDIT_FREEZE: () => statusRoutes.creditFreeze,
  CANCELED_HARD: () => statusRoutes.manualReview,
  CANCELED_SOFT: () => statusRoutes.manualReview,
  CANCELED_DUPE: () => statusRoutes.manualReview,
  PREQUAL_INFO_GIVEN: () => appRoutes.personalInfo,
  VERIFY_DOC: () => statusRoutes.manualReview,
  VERIFY_PAYMENT: () => statusRoutes.manualReview,
  SHIPPED: () => statusRoutes.manualReview,
  DELIVERED: () => statusRoutes.manualReview,
};
