import { Menu as M, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/solid';
import SvgHamburgerMenu from '@pesto/hubble-shared/icons/components/HamburgerMenu';
import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { extractInitials } from '@pesto/hubble-shared/utils/extractInitials';
import { Fragment, type ReactNode } from 'react';

import { useGoRoutes } from '../../../hooks/useGoRoutes';
import { useUser } from '../../../hooks/useUser';

interface MenuLinkProps {
  children: ReactNode;
  onClick?: () => void;
  active?: boolean;
}
const MenuLink = (props: MenuLinkProps) => {
  const { onClick, children, active } = props;
  return (
    <button
      onClick={onClick}
      className={cn(
        'w-full whitespace-pre rounded-md px-4 py-2 text-sm text-gray-700',
        'transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:outline-none',
        { 'bg-gray-200': active },
      )}
    >
      {children}
    </button>
  );
};

const Menu = () => {
  const { gotoApp } = useGoRoutes();
  const { signOut } = useAuth();
  const { user } = useUser();
  const userHasInitials = user?.firstName !== null && user?.lastName !== null;

  const logOutHandler = () => {
    signOut(true);
  };

  const userInitials = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
  };

  const menuItems = [
    {
      name: 'Application',
      onClick: gotoApp,
      enabled: !!user,
    },
    {
      name: 'Sign out',
      onClick: logOutHandler,
      enabled: !!user,
    },
  ];

  const renderMenu = () => {
    if (!user) return null;
    return (
      <div className="flex flex-grow gap-2">
        <M as="div" className="relative flex items-center justify-center">
          <M.Button className="flex w-full">
            {user ? (
              <div className="border-basic bg-salsa flex h-[29px] w-[29px] cursor-pointer items-center justify-center rounded-full border-[1px] text-[15px] text-white transition-all duration-300 ease-in-out hover:bg-black">
                {userHasInitials ? extractInitials(userInitials) : <UserIcon className="p-1 text-white" />}
              </div>
            ) : (
              <div className="rounded-md p-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:outline-none">
                <SvgHamburgerMenu className={'h-[20px] w-[20px]'} />
              </div>
            )}
          </M.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <M.Items className="absolute right-0 top-6 z-10 mt-3 w-48 origin-top-right overflow-hidden rounded-md bg-white py-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <M.Item>
                {({ active }) => (
                  <>
                    {menuItems.map((item, index) => {
                      if (!item.enabled) return null;
                      return (
                        <MenuLink key={index} onClick={item.onClick} active={active}>
                          {item.name}
                        </MenuLink>
                      );
                    })}
                  </>
                )}
              </M.Item>
            </M.Items>
          </Transition>
        </M>
      </div>
    );
  };

  return renderMenu();
};

export default Menu;
