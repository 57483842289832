import type { BaseCheckboxProps } from './CheckBoxTypes';
import { generateRootStyle } from './generateRootStyle';

export const CheckerIcon = (props: BaseCheckboxProps) => {
  const { className, checked } = props;
  const rootStyle = generateRootStyle({
    className,
    isChecked: checked,
    variant: 'checker',
  });

  return (
    <div className={rootStyle}>
      {checked ? <img src="/assets/images/white-check-icon.svg" alt={`Checker: ${checked}`} /> : null}
    </div>
  );
};
