import { cn } from '@pesto/hubble-shared/utils/cn';
import type { AuthCodeProps } from 'react-auth-code-input';
import AuthCode from 'react-auth-code-input';

import { VERIFICATION_CODE_LENGTH } from '../../constants/application';

type PestoCodeProps = {
  className?: string;
  onChange: (code: string) => void;
  error?: boolean;
};
type PestoCodeType = AuthCodeProps & PestoCodeProps;
export const PestoCode = (props: PestoCodeType) => {
  const { onChange, error, autoFocus = true, className, inputClassName, containerClassName, ...otherProps } = props;
  const rootStyle = cn(className, 'flex w-full justify-center');
  const containerStyle = cn(containerClassName, 'flex flex-row gap-2 justify-center max-w-[300px]');
  const inputStyle = cn(
    inputClassName,
    'p-2 w-[39px] h-[56px] text-center text-2xl font-bold border-[1px] rounded-sm outline-0',
    {
      'border-danger': error,
      'border-neutralSoft focus:border-basicSoft': !error,
    },
  );

  return (
    <div className={rootStyle} data-testid="pesto-code">
      <AuthCode
        length={VERIFICATION_CODE_LENGTH}
        containerClassName={containerStyle}
        inputClassName={inputStyle}
        onChange={onChange}
        autoFocus={autoFocus}
        allowedCharacters="numeric"
        {...otherProps}
      />
    </div>
  );
};
