import type { ReactNode } from 'react';
import { useState } from 'react';

import SvgInfo from '../../icons/components/Info';
import { cn } from '../../utils/cn';

interface InfoTooltipProps {
  toolTipText?: string | ReactNode;
  toolTipDirection?: 'up' | 'down';
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { toolTipText, toolTipDirection = 'down' } = props;
  const [showPopUp, setShowPopup] = useState(false);

  const toolTipStyle = cn('absolute z-[1] max-w-xs rounded-xl bg-darkShade px-4 py-4 text-white', {
    'right-0 bottom-9': toolTipDirection === 'up',
    'right-0 top-9': toolTipDirection === 'down',
  });

  const toolTipOrModal = <div className={toolTipStyle}>{toolTipText}</div>;

  return (
    <>
      <div className="ml-4">
        {showPopUp && toolTipOrModal}
        <SvgInfo
          className="mx-auto h-8 w-6"
          onMouseOver={() => {
            setShowPopup(true);
          }}
          onMouseOut={() => {
            setShowPopup(false);
          }}
        />
      </div>
    </>
  );
};
