import { yupResolver } from '@hookform/resolvers/yup';
import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';
import { EmailEnterForm } from '../../components/EmailEnterForm';
import { EnterOtpForm } from '../../components/EnterOtpForm';

interface FormData {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

interface EmailSignInScreenProps {
  title?: string;
  description?: string;
  hideSignUpButton?: boolean;
}

export const EmailSignInScreen = ({
  title = 'Sign In',
  description = 'Enter the code we sent to your email',
}: EmailSignInScreenProps) => {
  const { navigate } = useGoRoutes();
  const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState({
    sentCodeError: '',
    verifyCodeError: '',
  });

  const {
    watch,
    setValue,
    register,
    formState: { errors, isSubmitted },
    trigger,
    handleSubmit,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });

  const emailTyped = watch('email');

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [emailTyped, isSubmitted, trigger]);

  const [isLoading, setIsLoading] = useState(false);
  const { sendEmailCode, verifyEmailCode, codeLoading } = useAuth();

  const passwordLessStartHandler = handleSubmit(data => {
    const phone = data.email;
    sendEmailCode(phone)
      .then(() => {
        setError({
          sentCodeError: '',
          verifyCodeError: '',
        });
        setCodeSent(true);
      })
      .catch((err: { description: string }) => {
        setError({
          sentCodeError: err.description,
          verifyCodeError: '',
        });
      });
  });

  const passwordLessVerifyHandler = (code: string) => {
    setIsLoading(true);
    verifyEmailCode(emailTyped, code)
      .then(() => navigate('/'))
      .catch((err: { description: string }) => {
        setIsLoading(false);
        setError({
          sentCodeError: '',
          verifyCodeError: err.description,
        });
      });
  };

  if (isLoading) {
    return <LoaderSpinnerFull variant={'full'} className={'h-[200px]'} />;
  }
  return (
    <div className="flex max-w-sm flex-col gap-4">
      <Typography variant={'heroSmall'}>{title}</Typography>
      <Typography variant={'body'}>{description}</Typography>

      <Typography variant={'body'} className={'text-danger'}>
        {error.sentCodeError || error.verifyCodeError}
      </Typography>
      {!codeSent && (
        <EmailEnterForm
          onSubmit={passwordLessStartHandler}
          register={register}
          email={emailTyped}
          setValue={setValue}
          errors={errors}
          codeLoading={codeLoading}
        />
      )}
      {codeSent && (
        <EnterOtpForm
          type={'email'}
          phoneOrEmail={emailTyped}
          error={error}
          sendCode={sendEmailCode}
          onEdit={() => setCodeSent(false)}
          onSubmit={passwordLessVerifyHandler}
        />
      )}
    </div>
  );
};
