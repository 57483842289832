import { CreditApplicationStatus } from '@pesto/hubble-shared/__generated__/graphql/api';
import { useCallback, useMemo } from 'react';
import { useNavigate, type NavigateOptions } from 'react-router-dom';

import { getURLPathForStatus } from '../../utils/navigation/navigationHelper';
import { appRoutes, routes } from '../router/routes';

export const useGoRoutes = () => {
  const gotoRoute = useNavigate();
  const goBack = useCallback(() => gotoRoute(-1), [gotoRoute]);

  return useMemo(() => {
    return {
      navigate: (route: string, options?: NavigateOptions) => gotoRoute(route, options),

      goBack: () => {
        goBack();
      },

      gotoHome: () => {
        gotoRoute(routes.home);
      },
      gotoApp: () => {
        gotoRoute(routes.app);
      },
      gotoPersonalInfo: () => {
        gotoRoute(appRoutes.personalInfo);
      },
      gotoOffer: () => {
        gotoRoute(appRoutes.offer);
      },
      gotoCancelOffer: () => {
        gotoRoute(appRoutes.cancelOffer);
      },
      gotoFinalAgreements: () => {
        gotoRoute(appRoutes.agreements);
      },
      gotoCongratulations: () => {
        gotoRoute(appRoutes.congratulations);
      },
      gotoStatus: (status: CreditApplicationStatus | null | undefined) => {
        gotoRoute(getURLPathForStatus(status || CreditApplicationStatus.InvalidState));
      },
    };
  }, [goBack, gotoRoute]);
};
