import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import { LoginGetUser } from '../page/Login/LoginGetUser';
import { ApplicationFlow } from '../pages/App/ApplicationFlow';
import { useOffer } from '../pages/App/HomeLightFlow/hooks/useOffer';
import Agreements from '../pages/App/HomeLightFlow/pages/Agreements/Agreements';
import { CancelOffer } from '../pages/App/HomeLightFlow/pages/CancelOffer';
import Congrats from '../pages/App/HomeLightFlow/pages/Congrats/Congrats';
import { Offer } from '../pages/App/HomeLightFlow/pages/Offer';
import UserInfo from '../pages/App/HomeLightFlow/pages/UserInfo';
import { CreditFreeze } from '../pages/App/Status/CreditFreeze/CreditFreeze';
import { DuplicateStatus } from '../pages/App/Status/DuplicateStatus/DuplicateStatus';
import InvalidState from '../pages/App/Status/InvalidState/InvalidState';
import { ManualReviewStep } from '../pages/App/Status/ManualReviewStep/ManualReviewStep';
import { OfferRejected } from '../pages/App/Status/OfferRejected/OfferRejected';
import { OtherReview } from '../pages/App/Status/OtherReview/OtherReview';
import { HomePage } from '../pages/HomePage';
import { LoginCallback } from '../pages/LoginCallback';

import { ProtectedRoute } from './ProtectedRoute';
import { appRoutesExact, routes, statusRoutesExact } from './routes';

const RouterProvider = () => {
  const {
    handleOffer,
    rejectOfferHandler,
    goBackHandler,
    verifyIncome: { finalAgreementData, handleSubmitSSN },
    agreements: { modalError, setModalError, completeCreditApplication, loading },
  } = useOffer();

  const applicationRoutes = useRoutes([
    { path: '*', element: <div>Not Found</div> },
    { index: true, element: <HomePage /> },
    {
      path: routes.app + '/*',
      element: (
        <ProtectedRoute>
          <ApplicationFlow />
        </ProtectedRoute>
      ),
      children: [
        { index: true, element: <ApplicationFlow /> },
        {
          path: appRoutesExact.personalInfo,
          element: <UserInfo onSubmit={handleSubmitSSN} editableSSN={true} />,
        },
        {
          path: appRoutesExact.offer,
          element: <Offer onReject={rejectOfferHandler} onSubmit={accepted => handleOffer(accepted)} />,
        },
        {
          path: appRoutesExact.cancelOffer,
          element: <CancelOffer onBack={goBackHandler} onReject={accepted => handleOffer(accepted)} />,
        },
        {
          path: appRoutesExact.agreements,
          element: (
            <Agreements
              finalAgreementData={finalAgreementData}
              onBack={goBackHandler}
              onSubmit={completeCreditApplication}
              modalError={modalError}
              setModalError={setModalError}
              loading={loading}
            />
          ),
        },
        { path: appRoutesExact.congratulations, element: <Congrats /> },
        {
          path: appRoutesExact.status,
          children: [
            { path: statusRoutesExact.creditFreeze, element: <CreditFreeze /> },
            { path: statusRoutesExact.manualReview, element: <ManualReviewStep /> },
            { path: statusRoutesExact.inReview, element: <OtherReview /> },
            { path: statusRoutesExact.offerRejected, element: <OfferRejected /> },
            { path: statusRoutesExact.invalidState, element: <InvalidState /> },
            { path: statusRoutesExact.loginToYourAccount, element: <DuplicateStatus /> },
          ],
        },
      ],
    },

    { path: routes.loginCallback, element: <LoginCallback /> },
    { path: routes.loginGetUser, element: <LoginGetUser /> },
  ]);

  return (
    <>
      <Suspense fallback={<LoaderSpinnerFull />}>{applicationRoutes}</Suspense>
    </>
  );
};

export default RouterProvider;
