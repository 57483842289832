import { useUnsafeOnMount } from '@pesto/hubble-shared/hooks/useOnMount';

import { useGoRoutes } from '../../hooks/useGoRoutes';
import { useUser } from '../../hooks/useUser';

export const LoginGetUser = () => {
  const { getUserData } = useUser();
  const { gotoApp } = useGoRoutes();

  const logUserIn = () => {
    getUserData()
      .then(() => {
        return gotoApp();
      })
      .catch(err => {
        console.error(err);
        return gotoApp();
      });
  };

  useUnsafeOnMount(() => {
    logUserIn();
  });

  return (
    <div className={'flex h-screen flex-col items-center justify-center'}>
      <div className={'h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-gray-900'}></div>
    </div>
  );
};
