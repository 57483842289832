import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { useLayoutEffect } from 'react';

import { routes } from '../router/routes';

export const LoginCallback = () => {
  const { parseAuthToken } = useAuth();
  useLayoutEffect(() => {
    parseAuthToken().then(state => {
      console.log('state', state);

      return window.location.replace(routes.loginGetUser);
    });
  }, [parseAuthToken]);

  return (
    <div className={'flex h-screen flex-col items-center justify-center'}>
      <div className={'h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-gray-900'}></div>
    </div>
  );
};
export default LoginCallback;
