import { onError } from '@apollo/client/link/error';

import type { CurrentUserQuery } from '../../__generated__/graphql/api';
import { CurrentUserDocument } from '../../__generated__/graphql/api';
import { apolloClient } from '../Apollo';

export const useHandleApolloErrors = () => {
  return onError(({ graphQLErrors, networkError, operation }) => {
    const userId = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser?.id;

    const ifUserIsLoggedIn = userId ? `userID: ${userId}` : '';
    if (!ifUserIsLoggedIn) return;
    if (graphQLErrors) {
      if (!graphQLErrors) {
        return console.error(
          `[GraphQL error]: ${ifUserIsLoggedIn}, operation - ${operation.operationName} -> Message: `,
          graphQLErrors,
        );
      }
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: ${ifUserIsLoggedIn}, operation - ${
            operation.operationName
          } -> Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`,
        );
      });
    }
    if (networkError)
      console.error(`[Network error]: ${ifUserIsLoggedIn} , operation - ${operation.operationName} -> ${networkError}`);
  });
};
