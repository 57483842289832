import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { useEffect, type ReactNode } from 'react';

import { useGoRoutes } from '../hooks/useGoRoutes';
import { useUser } from '../hooks/useUser';
import { routes } from '../router/routes';

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useUser();
  const { navigate } = useGoRoutes();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(routes.login);
    }
  }, [isAuthenticated, user, navigate]);

  return <>{children}</>;
};
