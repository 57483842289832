import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { BackIcon } from '@pesto/hubble-shared/icons/components';
import { CircleHelpIcon, MapPinHouse } from 'lucide-react';

import homeRoof from '../../../../assets/homelight/home-roof-1.png';
import { CreditOfferWidget } from '../../../../components/SidebarApplication/CreditOfferWidget';
import { formatAddress } from '../components/FormattedAddress';
import { SingleAddress } from '../components/SingleAddress';

interface DecideOfferProps {
  onReject: () => void;
  onSubmit: (accepted: boolean) => void;
}

export const Offer = (props: DecideOfferProps) => {
  const { onReject, onSubmit } = props;
  const { data } = useCurrentUserQuery();
  const rejectOfferButtonText = 'Reject offer';
  const acceptOfferButtonText = 'Accept offer';

  return (
    <>
      <div className="flex h-full flex-col items-center gap-4 md:gap-8">
        <div className="grid flex-1">
          <div className="col-start-1 row-start-1 flex flex-1 items-end overflow-hidden rounded-lg bg-[#0073C3]">
            <img src={homeRoof} alt="home1" className={'w-full object-contain object-center'} />
          </div>
          <div className="col-start-1 row-start-1 flex flex-col items-center justify-start gap-8 px-4 pb-24 pt-10 sm:pb-[250px] sm:pt-12">
            <Typography variant={'headerLarge'} className="text-white">
              Confirm your offer
            </Typography>
            <CreditOfferWidget
              offerAmount={data?.currentUser?.creditApplication?.offerAmount || ''}
              className="w-auto text-white"
            />
            <SingleAddress
              addressName="HELOC Address"
              addressString={formatAddress(data?.currentUser?.billingAddress || null)}
              icon={<MapPinHouse />}
              className="w-auto border-white bg-opacity-0 text-white"
              addressLineClassName="text-white"
            />
            <div className="mx-auto flex max-w-xl gap-4 rounded border border-white p-6 text-white">
              <CircleHelpIcon className="h-10 w-10" />
              <div className="flex flex-col gap-2">
                <Typography variant="headerMedium">Next steps:</Typography>

                <Typography variant="body">
                  You may either <b>accept</b> or <b>reject</b> this credit offer.
                </Typography>
                <Typography variant="body">
                  If you accept this credit offer, you will need to verify your information, review the credit terms,
                  and your credit will be checked.
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <ButtonContainer
          column
          rightButton={
            <Button
              onClick={() => onSubmit(true)}
              buttonType="primary"
              data-testid="accept-offer-button"
              text={acceptOfferButtonText}
            />
          }
          leftButton={
            <Button
              onClick={onReject}
              buttonType="tertiary"
              data-testid="reject-offer-button"
              text={rejectOfferButtonText}
              className="!h-[40px] w-[45px]"
              iconLeft={<BackIcon />}
            />
          }
        />
      </div>
    </>
  );
};
