const stripStartingSlash = (path: string) => (path.startsWith('/') ? path.slice(1) : path);

const exactRoutesHelper = (routes: Record<string, string>, baseRoute: string) =>
  Object.keys(routes).reduce(
    (acc, key) => ({
      ...acc,
      [key]: stripStartingSlash(routes[key as keyof typeof routes].replace(baseRoute, '')),
    }),
    {} as typeof routes,
  );

export const routes = {
  home: '/',
  login: '/',
  loginCallback: '/login-callback',
  loginGetUser: '/login-get-user',
  app: '/app',
};

export const appRoutes = {
  home: '/app',
  personalInfo: '/app/personal-info',
  offer: '/app/offer',
  cancelOffer: '/app/cancel-offer',
  agreements: '/app/agreements',
  congratulations: '/app/congratulations',
  status: '/app/status',
};

export const appRoutesExact = exactRoutesHelper(appRoutes, routes.app);

export const statusRoutes = {
  inReview: appRoutes.status + '/in-review',
  manualReview: appRoutes.status + '/manual-review',
  creditFreeze: appRoutes.status + '/credit-freeze',
  offerRejected: appRoutes.status + '/offer-rejected',
  notPrequalified: appRoutes.status + '/not-prequalified',
  creditDenied: appRoutes.status + '/credit-denied',
  invalidState: appRoutes.status + '/invalid-state',
  dontOperateInState: appRoutes.status + '/dont-operate-in-state',
  loginToYourAccount: appRoutes.status + '/login-to-your-account',
};

export const statusRoutesExact = exactRoutesHelper(statusRoutes, appRoutes.status);
