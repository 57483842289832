import { CheckCircleIcon, Edit2, FileIcon, HomeIcon } from 'lucide-react';
import { Outlet, useLocation } from 'react-router-dom';

import type { StepProps } from '../../../components/SidebarApplication/Stepper';
import { useUser } from '../../../hooks/useUser';
import { ApplicationLayoutWithSidebar } from '../../../layouts/ApplicationLayoutWithSidebar';
import { ApplicationProvider } from '../../../providers/ApplicationProvider';
import { appRoutes } from '../../../router/routes';

const HomeLightFlow = () => {
  const { pathname } = useLocation();
  const { user } = useUser();
  const showSideBar = [appRoutes.personalInfo, appRoutes.offer, appRoutes.agreements, appRoutes.congratulations].some(
    route => pathname.includes(route),
  );

  const offerAmount = user?.creditApplication?.offerAmount;
  const stepNumber = {
    [appRoutes.personalInfo]: 0,
    [appRoutes.offer]: 1,
    [appRoutes.agreements]: 2,
    [appRoutes.congratulations]: 3,
  };

  const steps: StepProps[] = [
    {
      title: 'Personal Information',
      description: 'Fill in your personal information',
      icon: <HomeIcon />,
    },
    {
      title: 'Offer',
      description: 'Review the offer and accept it',
      icon: <FileIcon />,
    },
    {
      title: 'Agreements',
      description: 'Sign the agreements',
      icon: <Edit2 />,
    },
    {
      title: 'Congratulations!',
      description: 'You have successfully applied for a loan',
      icon: <CheckCircleIcon />,
    },
  ];

  return (
    <ApplicationProvider>
      <ApplicationLayoutWithSidebar
        steps={steps}
        activeStep={stepNumber[pathname]}
        offerAmount={offerAmount}
        showSideBar={showSideBar}
      >
        <Outlet />
      </ApplicationLayoutWithSidebar>
    </ApplicationProvider>
  );
};
export default HomeLightFlow;
