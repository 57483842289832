import { routes } from '../../../router/routes';

import LogoContainer from './LogoContainer';
import Menu from './Menu';

interface NavigationProps {
  className?: string;
  logoLink?: string;
  menuComponent?: React.ReactNode;
}

const Navigation = (props: NavigationProps) => {
  const { className, logoLink = routes.home, menuComponent } = props;
  return (
    <div className={`flex w-full flex-row items-center justify-between py-4 ${className}`}>
      <LogoContainer logoLink={logoLink} />
      <div className="ml-[10px] flex flex-row items-center">
        {menuComponent ? (
          <>
            {menuComponent} <Menu />
          </>
        ) : (
          <Menu />
        )}
      </div>
    </div>
  );
};

export default Navigation;
