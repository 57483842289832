import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import clsx from 'clsx';

interface ManualReviewStepProps {
  className?: string;
}

export const DuplicateStatus = (props: ManualReviewStepProps) => {
  const { className } = props;

  const { signOut } = useAuth();

  const buttonText = 'Log in to your other account';

  const signOutAndNavigateToSignIn = () => {
    signOut(true);
  };
  const rootStyle = clsx(
    'flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 md:h-[76vh] justify-center items-center',
    className,
  );
  return (
    <Wrapper1280 className={rootStyle}>
      <Typography variant={'heroSmall'}>Log in to your other account</Typography>
      <img
        src="/assets/images/status/manualReviewStep.svg"
        className={'max-w-[350px]'}
        alt={'manual review illustration'}
      />
      <Typography variant={'headerSmall'}>We have detected a duplicate account</Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Based on the information you have provided, it appears that this is a duplicate account. Please log in to the
        other one.
      </Typography>

      <Button onClick={signOutAndNavigateToSignIn} buttonType={'secondary'} text={buttonText} />
    </Wrapper1280>
  );
};
