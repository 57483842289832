import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import LoadingSpinner from '@pesto/hubble-shared/components/LoadingSpinner';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import CheckBox from '@pesto/hubble-shared/components/UI/CheckBox/CheckBox';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { useIsMobile } from '@pesto/hubble-shared/hooks/useIsMobile';
import SvgDownload from '@pesto/hubble-shared/icons/components/Download';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { type FC, useEffect } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ERROR_MESSAGES } from '../../../../../../../utils/sharedFieldValidations';

const finalAgreementsSchema = yup.object().shape({
  agree3: yup.bool().oneOf([true], ERROR_MESSAGES.agree),
  agree4: yup.bool().oneOf([true], ERROR_MESSAGES.agree),
  agree5: yup.bool().oneOf([true], ERROR_MESSAGES.agree),
});

interface AgreementFormProps {
  initialAgreementState?: {
    agree3?: boolean;
    agree4?: boolean;
    agree5?: boolean;
  };
  onSubmit?: SubmitHandler<{ agree3: boolean; agree4: boolean; agree5: boolean }>;
  isLoading?: boolean;
  agreementModal?: React.ReactNode;
  onCancel?: () => void;
  agreementImageForMobile?: string;
  links: {
    rateDisclosureLink: string;
    termsOfServiceLink: string;
    rewardTermsLink: string;
    cardholderAgreementLink: string;
    privacyNoticeLink: string;
    privacyPolicyLink: string;
    securityAgreementLink: string;
    patriotActLink: string;
  };
}

const AgreementForm: FC<AgreementFormProps> = ({
  onSubmit,
  isLoading,
  agreementModal,
  onCancel,
  links,
  agreementImageForMobile,
}) => {
  const isMobile = useIsMobile();

  const {
    watch,
    setValue,
    formState: { errors, isSubmitted },
    handleSubmit,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: yupResolver(finalAgreementsSchema),
    defaultValues: {
      agree3: false,
      agree4: false,
      agree5: false,
    },
  });

  const formValues = {
    agree3: watch('agree3'),
    agree4: watch('agree4'),
    agree5: watch('agree5'),
  };

  const { agree3, agree4, agree5 } = formValues;

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [agree3, agree4, agree5, isSubmitted, trigger]);

  useEffect(() => {
    if (isMobile && Object.keys(errors).length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [errors, isMobile]);

  const linkStyle = 'text-action underline hover:no-underline';

  const agreement3Text = (
    <>
      By checking this box, you confirm you have reviewed and agree to{' '}
      <a href={links.cardholderAgreementLink} target={'_blank'} className={linkStyle}>
        Pesto’s Consumer Cardholder Agreement
      </a>
      ,{' '}
      <a href={links.termsOfServiceLink} target={'_blank'} className={linkStyle}>
        Terms of Service
      </a>
      ,{' '}
      <a href={links.rewardTermsLink} target={'_blank'} className={linkStyle}>
        Rewards Program Terms and Conditions
      </a>
      ,{' '}
      <a href={links.privacyNoticeLink} target={'_blank'} className={linkStyle}>
        Privacy Notice
      </a>
      , and{' '}
      <a href={links.privacyPolicyLink} target={'_blank'} className={linkStyle}>
        Privacy Policy
      </a>
      .
    </>
  );
  const agreement4Text = (
    <>
      By checking this box, you confirm you have reviewed and you agree to the{' '}
      <a href={links.securityAgreementLink} target={'_blank'} className={linkStyle}>
        Security Agreement
      </a>
      .
    </>
  );
  const agreement5Text = (
    <>
      By checking this box, you confirm you have reviewed the{' '}
      <a href={links.patriotActLink} target={'_blank'} className={linkStyle}>
        U.S Patriot Act Notice
      </a>
      .
    </>
  );

  if (isLoading) {
    return (
      <div className="flex h-screen w-full flex-col justify-center">
        <LoadingSpinner />
        <Typography variant="bodyLarge" className="mt-8 max-w-sm self-center">
          We are processing your application. Please wait. This may take a few minutes.
        </Typography>
      </div>
    );
  }

  const rootStyles = cn('flex flex-col gap-4 w-full');

  return (
    <div className={rootStyles}>
      <div className="flex w-full flex-col items-center gap-4 text-left">
        <div className="text-center">
          <Typography variant={'headerLarge'}>Accept credit terms</Typography>
          <Typography variant="body">Deposit accounts and cards issued by Continental Bank.</Typography>
        </div>

        <div className="flex flex-col justify-center">
          <div className="flex w-full flex-col items-center text-center">
            {isMobile ? (
              <div className="border-neutral flex h-[400px] !w-[80vw] flex-col items-center overflow-auto rounded-lg border sm:!w-full">
                <img
                  src={agreementImageForMobile}
                  data-src={agreementImageForMobile}
                  loading="lazy"
                  alt="agreement pdf file"
                />
              </div>
            ) : (
              <iframe
                className="mx-auto h-48 w-full rounded border md:mt-10 md:h-96"
                src={`${links.rateDisclosureLink}#toolbar=0&view=FitW`}
                title="Disclosure of rate, fee, and other cost information"
                data-testid="documents"
              />
            )}
            <div className="flex w-full flex-row justify-center gap-2">
              <Button
                buttonType="secondary"
                className="mt-4 max-w-[90%] !justify-center md:max-w-[146px]"
                text="Download"
                onClick={() => {
                  window.open(links.rateDisclosureLink, '_blank');
                }}
                iconRight={<SvgDownload />}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(data => onSubmit?.(data as any))} className="my-10 px-3 text-left md:mx-10">
            <CheckBox
              dataTestId="agreement3"
              onChange={bool => setValue('agree3', bool)}
              value={agree3}
              text={agreement3Text}
              label="agreement3"
              error={errors.agree3?.message}
              required
            />
            <br />
            <CheckBox
              dataTestId="agreement4"
              onChange={bool => setValue('agree4', bool)}
              value={agree4}
              text={agreement4Text}
              label="agreement4"
              error={errors.agree4?.message}
              required
            />

            <br />
            <CheckBox
              dataTestId="agreement5"
              onChange={bool => setValue('agree5', bool)}
              value={agree5}
              text={agreement5Text}
              label="agreement5"
              error={errors.agree5?.message}
              required
            />
            <br />
          </form>
          {agreementModal}
          <ButtonContainer
            className="!z-50"
            rightButton={
              <Button
                className="!bg-green-400 !text-black"
                onClick={handleSubmit(data => {
                  onSubmit?.(data as any);
                })}
                type="submit"
                buttonType="primary"
                isLoading={isLoading}
                disabled={isLoading}
                text="Submit Application"
              />
            }
            helperText="Upon submission, a hard credit check is made. This may impact your credit score. We are not using this information to check your credit score. We are only using it to verify your application."
            leftButton={
              <Button
                className="text-neutral"
                onClick={onCancel}
                type="button"
                buttonType="tertiary"
                text="Cancel Application"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AgreementForm;
