import { forwardRef, type ReactNode } from 'react';

interface Wrapper1280Props {
  children: ReactNode;
  className?: string;
  id?: string;
}
export const Wrapper1280 = forwardRef<HTMLDivElement, Wrapper1280Props>((props, ref) => {
  const { children, className, id } = props;
  const wrapperStyle = `max-w-[1280px] mx-auto px-6 sm:px-8 ${className}`;
  return (
    <div ref={ref} className={wrapperStyle} id={id}>
      {children}
    </div>
  );
});

Wrapper1280.displayName = 'Wrapper1280';
