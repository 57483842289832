import { Player } from '@lottiefiles/react-lottie-player';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import SvgAppStore from '@pesto/hubble-shared/icons/components/AppStore';
import SvgCelebration from '@pesto/hubble-shared/icons/components/Celebration';
import SvgGooglePlay from '@pesto/hubble-shared/icons/components/GooglePlay';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { formatToCurrency } from '@pesto/hubble-shared/utils/formatterHelper';
import type { FC } from 'react';

import { links } from '../../../../../../constants/links';

interface CongratulationsProps {
  referenceNumber?: string;
  onIncreaseClick?: () => void;
  itemName?: string;
  creditLimit?: number | null;
  showIncreaseCredit?: boolean;
}

const Congratulations: FC<CongratulationsProps> = ({
  referenceNumber = '',
  onIncreaseClick,
  itemName = 'items',
  creditLimit,
  showIncreaseCredit = false,
}) => {
  const appStyles = 'w-[150px] h-[48px] cursor-pointer';
  const navigateToCardService = () => {
    window.location.href =
      'https://cardservicing.getpesto.com/cholder/loadSepUserRegistration.action?chrt=e4cadf05-53ff-498d-8d51-db6036cfd45d';
  };

  const referenceMessage = (
    <div className="flex flex-col gap-2 rounded border-[1px] border-slate-200 bg-slate-50 p-6">
      <div className="flex flex-row items-center justify-center">
        <span className="text-neutralStrong mr-1 text-sm">Reference number: </span>
        <span className="text-[22px]">{referenceNumber}</span>
      </div>

      <div className="text-basic text-[15px]">
        Use the reference code above to <br /> register and create a digital card
      </div>

      <div>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            navigateToCardService();
          }}
          target="_blank"
          className="text-action text-xs font-bold underline hover:no-underline"
        >
          Start spending today
        </a>
      </div>
    </div>
  );

  const increaseCreditLimit = showIncreaseCredit && (
    <div className="border-bisque bg-bisqueSoft w-full max-w-[440px] rounded border p-4">
      {creditLimit && (
        <>
          <Typography variant="body" className="inline">
            Your current credit limit is <span className="font-bold">{formatToCurrency(creditLimit)}</span>.
          </Typography>
          <br />
        </>
      )}
      <Typography variant="body" className="inline">
        If you would like to increase your credit limit, you can{' '}
      </Typography>{' '}
      <a
        href={''}
        onClick={e => {
          e.preventDefault();
          onIncreaseClick && onIncreaseClick();
        }}
        className="text-action inline-flex underline hover:no-underline"
      >
        <Typography variant="body">add more {itemName}.</Typography>
      </a>
    </div>
  );

  return (
    <>
      <Player
        autoplay
        speed={1}
        src="https://assets5.lottiefiles.com/packages/lf20_nxsyeqbd.json"
        className="absolute right-0 top-0 z-[-1] h-full w-full"
      />
      <div className="flex w-full max-w-[440px] flex-col items-center gap-4 text-center">
        <Typography variant="headerLarge" className="mb-10">
          Your card is on its way!
        </Typography>

        <div className="mb-[16px] flex justify-center">
          <SvgCelebration className="h-[160px] w-[160px]" />
        </div>

        {referenceNumber && referenceMessage}

        <Typography variant="body" className={`${!referenceNumber ? 'mb-0' : 'mb-[8px]'} !font-bold`}>
          Your application is complete and <br /> The Card® is on its way.
        </Typography>

        {!referenceNumber && (
          <div>
            <div className="text-neutralStrong mb-2 text-[11px]">
              Please look for an email from The Card® very soon.
            </div>

            <div className="text-neutralStrong text-[11px]">
              Once you have received your welcome email, you will be <br /> able to register The Card® account and a{' '}
              <br /> card will be sent to you
            </div>
          </div>
        )}

        {referenceNumber && (
          <div className="text-neutralStrong text-[11px]">
            Please allow up to 7-14 days for your card to arrive (some <br /> arrive sooner), follow the activation
            instructions that <br /> arrive with your new card, and enjoy using the purchasing <br /> power of The
            Card®.
          </div>
        )}

        <div className="text-neutral text-[10px] font-medium">
          Please view this information about your account terms: <br />
          <a
            href={links.accountOpeningDisclosures}
            target="_blank"
            className="text-action underline hover:no-underline"
          >
            Account Opening Disclosures
          </a>
        </div>

        {increaseCreditLimit}
        <div className={cn('bg-neutralInactive h-[2px] w-full', referenceNumber ? 'mt-0' : 'mt-[10px]')} />

        <div>
          <div className="text-neutralStrong text-xs font-normal">Download The Card® App:</div>
          <div className="mt-[8px] flex justify-center gap-4">
            <a href={links.appStoreUrl} target="_blank">
              <SvgAppStore className={appStyles} />
            </a>
            <a href={links.playStoreUrl} target="_blank">
              <SvgGooglePlay className={appStyles} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Congratulations;
