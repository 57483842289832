import { cn } from '@pesto/hubble-shared/utils/cn';
import { Link } from 'react-router-dom';

import theCardLogo from '../../../assets/homelight/the-card-logo.svg';
import { routes } from '../../../router/routes';

interface LogoContainerProps {
  logoLink?: string;
  className?: string;
}

const LogoContainer = (props: LogoContainerProps) => {
  const { logoLink = routes.home, className } = props;
  return (
    <Link to={logoLink}>
      <img src={theCardLogo} alt="Application Logo" className={cn('mx-auto h-[31px] w-[105px] sm:mx-0', className)} />
    </Link>
  );
};

export default LogoContainer;
