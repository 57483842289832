// typography component for the app to swap fonts and sizes and colors and heading or paragraph
import type { ReactNode } from 'react';
import React from 'react';

import { cn } from '../../utils/cn';

type Hero = 'heroLarge' | 'heroMedium' | 'heroSmall';
type SubHero = 'subHeroLarge' | 'subHeroMedium' | 'subHeroSmall';
type Header = 'headerLarge' | 'headerMedium' | 'headerSmall';
type Body = 'bodyXl' | 'bodyLarge' | 'body' | 'bodySmall';
type Caption = 'caption' | 'captionSmall';

export type TypographyVariant = Hero | SubHero | Header | Body | Caption;
interface TypographyProps {
  children: ReactNode;
  className?: string;
  variant: TypographyVariant;
}

// TODO: Refactor even more to make it more readable and improve the DX.
// TODO: Maybe add object like objects to define the typography styles and sizes like hero.large, hero.medium, hero.small
// TODO: Or do it like type='hero' size='large'

export const Typography: React.FC<TypographyProps> = ({ children, ...props }) => {
  const { className, variant } = props;

  const typographyConfig = {
    hero: {
      base: 'font-display',
      sizes: {
        Large: 'text-[68px] font-bold',
        Medium: 'text-[52px] font-bold',
        Small: 'text-[44px] font-bold',
      },
    },
    subHero: {
      base: 'font-display',
      sizes: {
        Large: 'text-[40px] font-bold',
        Medium: 'text-[40px] font-medium',
        Small: 'text-[30px] font-medium',
      },
    },
    header: {
      base: 'font-display',
      sizes: {
        Large: 'text-[28px] font-bold',
        Medium: 'text-[24px] font-bold',
        Small: 'text-[18px] font-bold',
      },
    },
    body: {
      base: 'font-sans',
      sizes: {
        Xl: 'text-[24px] font-normal',
        Large: 'text-[18px] font-normal',
        Regular: 'text-[15px] font-normal',
        Small: 'text-[12px] font-normal leading-[16px]',
      },
    },
    caption: {
      base: 'font-sans',
      sizes: {
        Regular: 'text-[14px] font-light',
        Small: 'text-[10px] leading-[12px]',
      },
    },
  };

  const getTypographyStyle = (variant: TypographyVariant): string => {
    const [group, size] = variant.split(/(?=[A-Z])/);
    const config = typographyConfig[group.toLowerCase() as keyof typeof typographyConfig];
    if (!config) return '';

    const style = config.sizes[size as keyof typeof config.sizes];
    return style ? `${config.base} ${style}` : '';
  };

  const typographyStyle = cn(getTypographyStyle(variant), className);
  return <div className={typographyStyle}>{children}</div>;
};
