import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { type ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { routes } from './routes';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { children } = props;
  const { data, loading: isLoading } = useCurrentUserQuery();
  const isLoggedIn = !!data?.currentUser;
  const isNotLoadingAndNotLoggedIn = !isLoading && !isLoggedIn;
  const navigate = useNavigate();

  useEffect(() => {
    if (isNotLoadingAndNotLoggedIn) {
      navigate(routes.login);
    }
  }, [data?.currentUser, isLoggedIn, isNotLoadingAndNotLoggedIn, navigate]);

  if (isLoading) return <LoaderSpinnerFull />;
  if (isNotLoadingAndNotLoggedIn) return <LoaderSpinnerFull />;

  return children;
};
