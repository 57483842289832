import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgHamburgerMenu = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 14"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={16} height={1.5} y={0.839} fill="#101820" rx={0.75} />
    <rect width={16} height={1.5} y={6.339} fill="#101820" rx={0.75} />
    <rect width={16} height={1.5} y={11.839} fill="#101820" rx={0.75} />
  </svg>
);
export default SvgHamburgerMenu;
