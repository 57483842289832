import { CardAd } from './CardAd';
import { CreditOfferWidget } from './CreditOfferWidget';
import { HelpCenter } from './HelpCenter';
import type { StepProps } from './Stepper';
import { StepperComponent } from './Stepper';

export interface SidebarApplicationProps {
  steps: StepProps[];
  activeStep: number;
  offerAmount: string | undefined | null;
}

export const SidebarApplication = (props: SidebarApplicationProps) => {
  const { steps, activeStep, offerAmount } = props;

  return (
    <div className="flex flex-1 flex-col items-center gap-8 rounded-lg border border-slate-200 bg-slate-50 p-6">
      <CreditOfferWidget offerAmount={offerAmount ?? ''} />
      <CardAd />
      <StepperComponent steps={steps} activeStep={activeStep} />
      <HelpCenter />
    </div>
  );
};
