import type { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCelebration = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 161 160"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#Celebration_svg__a)">
      <path
        fill="#FAFAFC"
        stroke="#FAFAFC"
        strokeWidth={1.28}
        d="M.5 80a80.002 80.002 0 0 0 136.569 56.569 80.005 80.005 0 0 0 0-113.137A80.002 80.002 0 0 0 .5 80Z"
      />
      <path
        fill="#101820"
        d="M108.233 149.547c0 1.769-11.873 3.2-26.52 3.2s-27.88-1.431-27.88-3.2 13.234-3.2 27.88-3.2c14.647 0 26.52 1.44 26.52 3.2"
        opacity={0.2}
      />
      <path fill="#F93549" d="m37.224 108.621 46.44 33.6a1.69 1.69 0 0 0 2.62-1.827l-15.46-55.2z" />
      <path
        fill="#F0D9EA"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M65.214 106.56a53 53 0 0 1-14.586 11.758l3.888 2.812a47 47 0 0 0 3.165-2.016 62.4 62.4 0 0 0 10.773-9.674 64 64 0 0 0 6.66-8.933l-1.66-5.92a56.7 56.7 0 0 1-8.24 11.973M72.3 116.723a56.8 56.8 0 0 1-10.777 9.475l3.742 2.708a62.6 62.6 0 0 0 10.275-9.3 65 65 0 0 0 3.658-4.52l-1.512-5.398a60.4 60.4 0 0 1-5.386 7.035M79.667 128.714a61 61 0 0 1-6.24 6.096l3.595 2.601a66 66 0 0 0 5.878-5.813c.236-.265.463-.531.692-.8l-1.43-5.105a53 53 0 0 1-2.495 3.021"
      />
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="m37.224 108.621 46.44 33.6a1.69 1.69 0 0 0 2.62-1.827l-15.46-55.2z"
      />
      <path
        fill="#FEDEC7"
        d="M37.223 108.619c.735 1.054 1.897 1.794 3.42 2.179s3.378.407 5.457.064c2.08-.342 4.344-1.042 6.664-2.06a40.7 40.7 0 0 0 6.855-3.872 40.7 40.7 0 0 0 6.003-5.093c1.757-1.825 3.197-3.707 4.237-5.54s1.661-3.58 1.827-5.142-.128-2.91-.863-3.963c-.735-1.054-1.897-1.794-3.42-2.18-1.523-.384-3.377-.406-5.457-.063-2.08.342-4.344 1.043-6.663 2.06a40.7 40.7 0 0 0-6.855 3.872 40.7 40.7 0 0 0-6.003 5.094c-1.757 1.824-3.197 3.706-4.238 5.54-1.04 1.832-1.66 3.58-1.826 5.142s.127 2.908.862 3.962"
      />
      <path
        fill="#FEDEC7"
        d="M71.734 88.434c-4.457-1.6-12.304.49-19.68 5.638-7.376 5.149-12.053 11.789-12.1 16.523a5.4 5.4 0 0 1-2.72-1.974c-3.09-4.432 1.92-13.269 11.2-19.738s19.305-8.12 22.4-3.68c.635.955.95 2.086.9 3.23"
      />
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M37.223 108.619c.735 1.054 1.897 1.794 3.42 2.179s3.378.407 5.457.064c2.08-.342 4.344-1.042 6.664-2.06a40.7 40.7 0 0 0 6.855-3.872 40.7 40.7 0 0 0 6.003-5.093c1.757-1.825 3.197-3.707 4.237-5.54s1.661-3.58 1.827-5.142-.128-2.91-.863-3.963c-.735-1.054-1.897-1.794-3.42-2.18-1.523-.384-3.377-.406-5.457-.063-2.08.342-4.344 1.043-6.663 2.06a40.7 40.7 0 0 0-6.855 3.872 40.7 40.7 0 0 0-6.003 5.094c-1.757 1.824-3.197 3.706-4.238 5.54-1.04 1.832-1.66 3.58-1.826 5.142s.127 2.908.862 3.962"
      />
      <path
        fill="#F0D9EA"
        d="m91.873 61.424-5.197 57.082a1.69 1.69 0 0 0 2.006 1.815c.345-.067.66-.239.903-.494l39.579-41.457z"
      />
      <path
        fill="#F93549"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M107.928 84.446a53 53 0 0 1-17.556-6.544l-.432 4.778a48 48 0 0 0 3.35 1.693 62.4 62.4 0 0 0 13.812 4.331c3.652.71 7.36 1.102 11.08 1.17l4.25-4.452c-4.857.3-9.732-.028-14.504-.976M102.803 95.726a56.8 56.8 0 0 1-13.648-4.435l-.42 4.6a62.6 62.6 0 0 0 13.239 4.091c1.936.378 3.856.64 5.76.84l3.87-4.054a61 61 0 0 1-8.801-1.042M96.248 108.181a62 62 0 0 1-8.426-2.256l-.403 4.419a66 66 0 0 0 8 2.093c.349.069.693.128 1.04.189l3.661-3.84a54 54 0 0 1-3.872-.605"
      />
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="m91.873 61.424-5.197 57.082a1.69 1.69 0 0 0 2.006 1.815c.345-.067.66-.239.903-.494l39.579-41.457z"
      />
      <path
        fill="#FEDEC7"
        d="M106.472 78.803a41 41 0 0 0 7.478 2.605c2.481.592 4.851.88 6.974.845 2.123-.034 3.957-.389 5.396-1.044s2.455-1.597 2.989-2.773c.534-1.175.576-2.56.123-4.075s-1.392-3.13-2.762-4.752-3.146-3.218-5.223-4.697a41 41 0 0 0-6.881-3.92 41 41 0 0 0-7.479-2.605c-2.48-.592-4.85-.88-6.973-.845-2.123.034-3.957.389-5.397 1.044-1.439.655-2.455 1.598-2.989 2.773s-.576 2.56-.123 4.075 1.392 3.13 2.763 4.752 3.145 3.218 5.223 4.697a41 41 0 0 0 6.881 3.92"
      />
      <path
        fill="#FEDEC7"
        d="M126.844 80.8c-.904-4.648-6.699-10.333-14.894-14.056s-16.29-4.344-20.386-1.97a5.4 5.4 0 0 1 .31-3.35c2.24-4.918 12.394-5.12 22.692-.434 10.298 4.687 16.834 12.46 14.598 17.38a5.4 5.4 0 0 1-2.32 2.43"
      />
      <path
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M106.472 78.803a41 41 0 0 0 7.478 2.605c2.481.592 4.851.88 6.974.845 2.123-.034 3.957-.389 5.396-1.044s2.455-1.597 2.989-2.773c.534-1.175.576-2.56.123-4.075s-1.392-3.13-2.762-4.752-3.146-3.218-5.223-4.697a41 41 0 0 0-6.881-3.92 41 41 0 0 0-7.479-2.605c-2.48-.592-4.85-.88-6.973-.845-2.123.034-3.957.389-5.397 1.044-1.439.655-2.455 1.598-2.989 2.773s-.576 2.56-.123 4.075 1.392 3.13 2.763 4.752 3.145 3.218 5.223 4.697a41 41 0 0 0 6.881 3.92"
      />
      <path
        fill="#C5E9CC"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M140.065 39.733a1.6 1.6 0 0 1-.128 1.771 12.38 12.38 0 0 1-8.602 4.877 1.6 1.6 0 0 1-1.584-.8l-1.973-3.48a1.596 1.596 0 0 1 .393-2.04 1.6 1.6 0 0 1 .676-.317 12.16 12.16 0 0 0 6.674-3.782 1.6 1.6 0 0 1 2.068-.259c.212.14.387.329.511.55zM68.5 39.733a1.6 1.6 0 0 1-.128 1.771 12.37 12.37 0 0 1-8.601 4.877 1.6 1.6 0 0 1-1.586-.8l-1.973-3.48a1.6 1.6 0 0 1 1.07-2.357 12.16 12.16 0 0 0 6.674-3.782 1.6 1.6 0 0 1 2.571.29z"
      />
      <path
        fill="#FDCF87"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M106.924 67.533a1.6 1.6 0 0 1-1.651-.651 12.39 12.39 0 0 1-2.08-9.668 1.6 1.6 0 0 1 1.237-1.28l3.912-.84a1.6 1.6 0 0 1 1.92 1.727c-.283 2.61.286 5.24 1.622 7.499a1.6 1.6 0 0 1-1.048 2.366zM37.317 26.123a1.6 1.6 0 0 1-1.65-.651 12.38 12.38 0 0 1-2.08-9.667 1.6 1.6 0 0 1 1.236-1.28l3.917-.834a1.6 1.6 0 0 1 1.92 1.727 12.16 12.16 0 0 0 1.61 7.499 1.6 1.6 0 0 1-1.049 2.366z"
      />
      <path
        fill="#61A7DA"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M25.35 33.976a1.6 1.6 0 0 0-1.773-.1 12.38 12.38 0 0 0-5.935 7.911 1.6 1.6 0 0 0 .592 1.674l3.2 2.4a1.6 1.6 0 0 0 2.475-.762 12.16 12.16 0 0 1 4.604-6.136 1.6 1.6 0 0 0 .038-2.587zM51.06 84.976a1.6 1.6 0 0 1 1.77-.14 12.37 12.37 0 0 1 6.117 7.767 1.6 1.6 0 0 1-.552 1.688l-3.143 2.475a1.6 1.6 0 0 1-2.49-.702 12.16 12.16 0 0 0-4.742-6.027 1.6 1.6 0 0 1-.099-2.586zM96.043 26.747a1.6 1.6 0 0 0-.648 1.653 12.38 12.38 0 0 0 5.656 8.109 1.6 1.6 0 0 0 1.776-.037l3.274-2.29a1.6 1.6 0 0 0 .052-2.587 12.16 12.16 0 0 1-4.388-6.291 1.6 1.6 0 0 0-1.008-1.046 1.6 1.6 0 0 0-1.439.2z"
      />
      <path
        fill="#FDCF87"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M79.292 21.392a1.6 1.6 0 0 1-.843 1.563 12.37 12.37 0 0 1-9.845.922 1.6 1.6 0 0 1-1.12-1.38l-.373-3.982a1.6 1.6 0 0 1 1.944-1.715c2.556.59 5.235.34 7.637-.717a1.6 1.6 0 0 1 2.225 1.322z"
      />
      <path
        fill="#CF63CE"
        stroke="#101820"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M120.004 20.57a1.6 1.6 0 0 1-.48-1.708 12.38 12.38 0 0 1 6.413-7.52 1.6 1.6 0 0 1 1.76.208l3.04 2.594a1.602 1.602 0 0 1-.198 2.58 12.17 12.17 0 0 0-4.975 5.84 1.6 1.6 0 0 1-1.104.945 1.6 1.6 0 0 1-1.413-.338zM37.676 67.464a1.6 1.6 0 0 1-.48-1.707 12.38 12.38 0 0 1 6.411-7.52 1.6 1.6 0 0 1 1.76.208l3.04 2.593a1.6 1.6 0 0 1-.196 2.581 12.16 12.16 0 0 0-4.975 5.84 1.6 1.6 0 0 1-2.517.607z"
      />
      <path
        stroke="#61A7DA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="M15.61 56.806v-1.539M18.875 58.16l1.087-1.09M20.227 61.424h1.539M18.875 64.69l1.087 1.088M15.61 66.042v1.539M12.345 64.69l-1.088 1.088M10.993 61.424H9.454M12.345 58.16l-1.088-1.09M103.455 2.94V1.403M106.72 4.293l1.088-1.088M108.071 7.557h1.539M106.72 10.822l1.088 1.088M103.455 12.174v1.54M100.19 10.822l-1.088 1.088M98.838 7.557h-1.54M100.19 4.293l-1.088-1.088M142.307 79.723v-1.537M145.572 81.077l1.088-1.088M146.924 84.34h1.539M145.572 87.606l1.088 1.088M142.307 88.958v1.54M139.043 87.606l-1.088 1.088M137.691 84.34h-1.54M139.043 81.077l-1.088-1.088"
      />
      <path
        stroke="#CF63CE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.28}
        d="m97.667 55.378-1.77-.621a2.053 2.053 0 0 1-1.26-2.616l.564-1.6a2.05 2.05 0 0 0-1.26-2.616l-1.6-.562a2.053 2.053 0 0 1-1.258-2.616l.563-1.6a2.05 2.05 0 0 0-1.26-2.616l-1.6-.561a2.053 2.053 0 0 1-1.259-2.616l.564-1.6a2.05 2.05 0 0 0-1.26-2.615l-1.6-.561M61.91 82.907l-1.312-1.342a2.05 2.05 0 0 1 .034-2.901l1.214-1.189a2.053 2.053 0 0 0 .034-2.902l-1.19-1.216a2.05 2.05 0 0 1 .033-2.901l1.216-1.189a2.053 2.053 0 0 0 .033-2.902l-1.188-1.215a2.054 2.054 0 0 1 .032-2.902l1.216-1.189a2.053 2.053 0 0 0 .032-2.902l-1.188-1.215M37.225 91.54l-1.856.273a2.05 2.05 0 0 1-2.33-1.733l-.246-1.683a2.053 2.053 0 0 0-2.328-1.731l-1.683.246a2.053 2.053 0 0 1-2.328-1.731l-.246-1.682a2.05 2.05 0 0 0-2.33-1.733l-1.682.248a2.05 2.05 0 0 1-2.328-1.732L19.62 78.6a2.05 2.05 0 0 0-2.32-1.731l-1.69.251M151.54 54.22l-.504 1.807a2.05 2.05 0 0 1-2.526 1.426l-1.639-.456a2.05 2.05 0 0 0-2.528 1.427l-.456 1.637a2.05 2.05 0 0 1-2.526 1.425l-1.638-.456a2.054 2.054 0 0 0-2.528 1.428l-.456 1.636a2.05 2.05 0 0 1-2.527 1.426l-1.638-.456a2.054 2.054 0 0 0-2.528 1.427l-.456 1.637"
      />
    </g>
    <defs>
      <clipPath id="Celebration_svg__a">
        <path fill="#fff" d="M.5 0h160v160H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCelebration;
