import { Player } from '@lottiefiles/react-lottie-player';
import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import clsx from 'clsx';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';

interface OfferRejectedStepProps {
  className?: string;
}
export const OfferRejected = (props: OfferRejectedStepProps) => {
  const { className } = props;
  const { gotoHome } = useGoRoutes();

  const buttonText = 'Contact support'.toUpperCase();

  const rootStyle = clsx(
    'flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 md:h-[76vh] justify-center items-center',
    className,
  );
  return (
    <Wrapper1280 className={rootStyle}>
      <Typography variant={'heroSmall'}>Offer is rejected.</Typography>
      <Player
        src="https://assets1.lottiefiles.com/packages/lf20_RxHG8n.json"
        className={'max-w-[350px]'}
        loop
        autoplay
      />

      <Typography variant={'headerSmall'}>Your item is on its way back to you.</Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Please reach out to us and we will continue with your Pesto Mastercard&reg; Application.
      </Typography>
      <ButtonContainer rightButton={<Button onClick={gotoHome} buttonType={'secondary'} text={buttonText} />} />
    </Wrapper1280>
  );
};
