import { cn } from '../utils/cn';

interface LoaderSpinnerFullProps {
  className?: string;
  variant?: 'full' | 'screen';
}
export const LoaderSpinnerFull = (props: LoaderSpinnerFullProps) => {
  const { className, variant = 'screen' } = props;
  const loaderClass = cn(
    ' flex flex-col items-center justify-center m-auto',
    {
      'h-full': variant === 'full',
      'h-screen': variant === 'screen',
    },
    className,
  );

  return (
    <div className={loaderClass}>
      <div className="border-t-5 h-32 w-32 animate-spin rounded-full border-b-2 border-gray-900"></div>
    </div>
  );
};
