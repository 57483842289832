import { useIsMobile } from '../../../../hooks/useIsMobile';
import { cn } from '../../../../utils/cn';
import { Typography } from '../../Typography';
import type { TextFieldProps } from '../FormTypes';

type TextFieldWrapperProps = Omit<TextFieldProps, 'onChange'>;

export const TextFieldWrapper = (props: TextFieldWrapperProps) => {
  const {
    className,
    children,
    required,
    errorText,
    error,
    caption,
    rightHelperText,
    info,
    label,
    textAlign = 'left',
  } = props;

  const isMobile = useIsMobile();

  const align = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  const rootStyle = cn(className, 'relative w-full', align[textAlign]);
  return (
    <div className={rootStyle}>
      <div className="text-slate-500">
        <label>
          <Typography variant={isMobile ? 'bodySmall' : 'body'}>
            {required && <span className="text-danger">*</span>}
            {label}
          </Typography>
        </label>
      </div>
      <div className="relative flex items-center justify-center text-[14px] sm:text-base">
        <div className="w-full">{children}</div>
        {rightHelperText && (
          <Typography className="absolute right-8 top-[8px] text-slate-400" variant="body">
            {rightHelperText}
          </Typography>
        )}
        <div>{info}</div>
      </div>
      {caption && (
        <Typography
          className="text-neutralStrong ml-[8px] mt-[8px] font-extralight"
          variant={isMobile ? 'captionSmall' : 'caption'}
        >
          {caption}
        </Typography>
      )}
      {error && <div className="text-danger mt-[8px] text-sm">{errorText}</div>}
    </div>
  );
};
