import type { CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { CurrentUserDocument, useStartNewApplicationMutation } from '@pesto/hubble-shared/__generated__/graphql/api';

import { getURLPathForStatus } from '../../utils/navigation/navigationHelper';

import { useGoRoutes } from './useGoRoutes';

export const useIncreaseCredit = (user: CurrentUserQuery['currentUser']) => {
  const [startNewApplication, { loading }] = useStartNewApplicationMutation({
    errorPolicy: 'all',
    refetchQueries: [{ query: CurrentUserDocument }],
    awaitRefetchQueries: true,
  });

  const { navigate } = useGoRoutes();

  const handleIncreaseClick = () => {
    startNewApplication({
      variables: {
        creditApplicationId: user?.creditApplication?.id || '',
      },
    })
      .then(res => {
        if (res?.errors) {
          console.error('Error starting new application - user:' + user?.id + res.errors[0].message);
          return;
        }
        const status = res.data?.startNewApplication?.status;
        const securityType = res.data?.startNewApplication?.securityType;
        if (!status || !securityType) return null;

        return navigate(getURLPathForStatus(status));
      })
      .catch(err => {
        console.error('Error starting new application - user:' + user?.id + err.message);
      });
  };
  return { loading, handleIncreaseClick };
};
