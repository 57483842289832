import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import homeRoof from '../../../assets/homelight/home-roof-1.png';
import { Header } from '../../../components/Header/Header';
import { useGoRoutes } from '../../../hooks/useGoRoutes';
import { useUser } from '../../../hooks/useUser';

interface LoginPageLayoutProps {
  className?: string;
  children?: ReactNode;
}



export const LoginPageLayoutCentered = (props: LoginPageLayoutProps) => {
  const { children } = props;
  const { navigate } = useGoRoutes();
  const { isLoading, isLoggedIn } = useUser();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-full">
        <div className="flex h-full items-center justify-center">
          <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-gray-900"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto h-full flex flex-col">
      <Header />
      <div className="grid flex-1">
          <div className="col-start-1 row-start-1 flex flex-1 items-end bg-[#0073C3]">
              <img src={homeRoof} alt="home1" className={'object-contain object-center w-full'} />
          </div>
        <Wrapper1280
          className={
            'col-start-1 row-start-1 flex items-center justify-center h-auto p-8'
          }
        >
          <div className="flex w-full flex-col px-6 py-8 bg-background rounded-xl border-slate-200 border">
            {children}
          </div>
        </Wrapper1280>
      </div>
    </div>
  );
};
