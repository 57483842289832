import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { cn } from '@pesto/hubble-shared/utils/cn';

import { useIncreaseCredit } from '../../../../hooks/useIncreaseCredit';

interface ManualReviewStepProps {
  className?: string;
}

export const ManualReviewStep = (props: ManualReviewStepProps) => {
  const { className } = props;
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;
  const { handleIncreaseClick, loading } = useIncreaseCredit(user);

  const showRestartCreditApplication = !!user?.canStartNewApplication;

  const buttonText = 'Return to the homepage';
  const navigateToPesto = () => {
    window.location.href = 'https://getpesto.com/';
  };
  const rootStyle = cn(
    'flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 md:h-[76vh] justify-center items-center',
    className,
  );
  return (
    <Wrapper1280 className={rootStyle}>
      <Typography variant={'heroSmall'}>We’ll be in touch.</Typography>
      <Typography variant={'headerSmall'}>We are reviewing your application.</Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Please check your email and the Pesto app for a follow-up from Pesto.
      </Typography>
      {showRestartCreditApplication && (
        <Button
          onClick={handleIncreaseClick}
          buttonType={'secondary'}
          text={'Re-apply'}
          isLoading={loading}
          disabled={loading}
        />
      )}
      <Button onClick={navigateToPesto} buttonType={'secondary'} text={buttonText} />
    </Wrapper1280>
  );
};
