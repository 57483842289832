import type { Address } from '@pesto/hubble-shared/__generated__/graphql/api';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { HomeIcon, MapPinHouse } from 'lucide-react';
import type { FC } from 'react';

import { SingleAddress } from './SingleAddress';

interface FormattedAddressProps {
  billingAddress?: Address | null;
}
export const formatAddress = (address: Address | null | undefined) => {
  if (!address) return '';
  const { addressFirstLine, suiteNumber, city, state, zip } = address;
  return `${addressFirstLine} ${suiteNumber}, ${city}, ${state}, ${zip}`;
};

export const FormattedAddress: FC<FormattedAddressProps> = ({ billingAddress }) => {
  // const isEmpty = useMemo(() => Object.values(billingAddress || {}).every(val => val === ''), [billingAddress]);

  const addresses = [
    {
      addressName: 'HELOC Address',
      addressString: formatAddress(billingAddress),
      icon: <MapPinHouse />,
    },
    {
      addressName: 'Billing Address',
      addressString: formatAddress(billingAddress),
      icon: <HomeIcon />,
    },
  ];

  return (
    <div className={cn('flex w-full gap-4')}>
      {addresses.map(address => (
        <SingleAddress key={address.addressName} {...address} />
      ))}
    </div>
  );
};
