import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { Navigate } from 'react-router-dom';

import { EmailLoginPage } from '../page/Login/EmailLoginPage/EmailLoginPage';
import { routes } from '../router/routes';

export const HomePage = () => {
  const { data, loading } = useCurrentUserQuery();
  const user = data?.currentUser;

  if (loading) {
    return <LoaderSpinnerFull />;
  }

  if (user) {
    return <Navigate to={routes.app} />;
  }

  return <EmailLoginPage />;
};
