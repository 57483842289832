import { CreditApplicationStatus, type User } from '@pesto/hubble-shared/__generated__/graphql/api';

import { statusHomelightHandlers } from './routeHelpers/homelightRouteHelper';

const flowHandler = (
  statusHandlers: Record<CreditApplicationStatus, (user?: User | null) => string>,
  creditStatus?: CreditApplicationStatus,
  currentUser?: User | null,
) => {
  const status = creditStatus || currentUser?.creditApplication?.status;
  const handler = statusHandlers[status || CreditApplicationStatus.InvalidState];
  return handler(currentUser);
};

export const getURLPathForStatus = (creditStatus?: CreditApplicationStatus) => {
  console.log(`navigate to ${flowHandler(statusHomelightHandlers, creditStatus)} -> ${creditStatus}`);

  return flowHandler(statusHomelightHandlers, creditStatus);
};
