import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { cn } from '@pesto/hubble-shared/utils/cn';
import type { ReactNode } from 'react';

import { Header } from '../components/Header/Header';
import type { SidebarApplicationProps } from '../components/SidebarApplication/SidebarApplication';
import { SidebarApplication } from '../components/SidebarApplication/SidebarApplication';

interface ApplicationLayoutProps extends SidebarApplicationProps {
  className?: string;
  children: ReactNode;
  showSideBar?: boolean;
}

export const ApplicationLayoutWithSidebar = ({
  steps,
  activeStep,
  offerAmount,
  children,
  className = '',
  showSideBar = true,
}: ApplicationLayoutProps) => {
  return (
    <div className="mx-auto">
      <Header divider />
      <Wrapper1280
        className={cn(
          'flex min-h-[80vh] flex-col items-stretch justify-center gap-4 pb-[240px] pt-4 sm:flex-row md:pb-[150px] md:pt-8',
          className,
        )}
      >
        <div className="flex flex-col items-center gap-4 sm:w-2/3">{children}</div>
        {showSideBar && <SidebarApplication steps={steps} activeStep={activeStep} offerAmount={offerAmount} />}
      </Wrapper1280>
    </div>
  );
};
