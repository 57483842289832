import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { cn } from '@pesto/hubble-shared/utils/cn';
import type { ReactNode } from 'react';

interface HeaderTypeProps {
  className?: string;
  divider?: boolean;
  beforeHeader?: ReactNode;
  children?: ReactNode;
  afterHeader?: ReactNode;
}

export const HeaderWrapper = ({
  className,
  divider,
  beforeHeader,
  afterHeader,
  children,
}: Partial<HeaderTypeProps>) => {
  const rootStyles = cn(
    'sticky top-0 z-[9998] bg-white border-b border-neutralLight',

    {
      'border-neutralSoft': divider,
    },
    className,
  );

  return (
    <>
      <div className={rootStyles}>
        {beforeHeader}
        <div>
          <Wrapper1280>{children}</Wrapper1280>
        </div>
      </div>
      {afterHeader}
    </>
  );
};
