import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { cn } from '@pesto/hubble-shared/utils/cn';

interface CreditOfferWidgetProps {
  offerAmount: string;
  className?: string;
}

export const CreditOfferWidget = (props: CreditOfferWidgetProps) => {
  const { offerAmount, className } = props;

  return (
    <div className={cn('flex flex-col items-center', className)}>
      <Typography variant="bodyLarge">Your credit offer</Typography>
      <Typography variant="heroSmall" className="font-bold">
        {offerAmount}
      </Typography>
    </div>
  );
};
