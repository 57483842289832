import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Modal2 } from '@pesto/hubble-shared/components/UI/Modal/Modal2';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

import mobileAgreementImage from '../../../../../assets/homelight/agreementItem.jpg';
import { links } from '../../../../../constants/links';
import type { FinalAgreementDataInterface } from '../../hooks/useOffer';

import AgreementForm from './AgreementForm/AgreementForm';

interface AgreementsProps {
  finalAgreementData: FinalAgreementDataInterface | null;
  onBack: () => void;
  onSubmit: () => void;
  modalError: boolean;
  setModalError: (value: boolean) => void;
  loading: boolean;
}

const Agreements = (props: AgreementsProps) => {
  const { onBack, onSubmit: completeCreditApplication, modalError, setModalError, loading } = props;

  const agreementLinks = {
    privacyNoticeLink: links.privacyNotice,
    privacyPolicyLink: links.privacyPolicy,
    rateDisclosureLink: links.rateDisclosureItem,
    termsOfServiceLink: links.termsOfService,
    rewardTermsLink: links.rewardsTerms,
    cardholderAgreementLink: links.cardholderAgreement,
    securityAgreementLink: links.securityAgreementItem,
    patriotActLink: links.patriotAct,
  };

  const onSubmit = () => {
    completeCreditApplication();
  };

  //This is HomeLight Flow
  return (
    <>
      <AgreementForm
        links={agreementLinks}
        agreementImageForMobile={mobileAgreementImage}
        onSubmit={onSubmit}
        isLoading={loading}
        onCancel={() => onBack()}
      />
      <Modal2 isOpen={modalError} onClose={() => setModalError(false)}>
        <div>
          <Typography variant="headerLarge">SSN or DOB mismatch</Typography>
          <Typography variant="bodyLarge">Please check your information and try again.</Typography>
          <Button buttonType="primary" text="Close" onClick={() => setModalError(false)} />
        </div>
      </Modal2>
    </>
  );
};

export default Agreements;
