const CLOUDFLARE_LEGAL_URL = 'https://files.getpesto.com/legal';

export const links = {
  privacyPolicy: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Policy.pdf`,
  privacyNotice: `${CLOUDFLARE_LEGAL_URL}/Pesto-Privacy-Notice.pdf`,
  termsOfService: `${CLOUDFLARE_LEGAL_URL}/Pesto-Terms-of-Service.pdf`,
  rewardsTerms: `${CLOUDFLARE_LEGAL_URL}/Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf`,
  eSignDisclosure: `${CLOUDFLARE_LEGAL_URL}/Pesto-E-Sign-Disclosure-and-Consent-to-Use-Electronic-Records-and-Signatures.pdf`,
  tcpa: `${CLOUDFLARE_LEGAL_URL}/Pesto-Telephone-Consumer-Protection-Act-Notice.pdf`,
  cardholderAgreement: `${CLOUDFLARE_LEGAL_URL}/Pesto-Consumer-Cardholder-Agreement.pdf`,
  packingAndShippingInstructions: `${CLOUDFLARE_LEGAL_URL}/Pesto-Preparing-your-Valuables-for-Shipment.pdf`,
  securityAgreementItem: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Item.pdf`,
  securityAgreementCash: `${CLOUDFLARE_LEGAL_URL}/Security-Agreement-Cash.pdf`,
  patriotAct: `${CLOUDFLARE_LEGAL_URL}/Pesto-Patriot-Act-Notice.pdf`,
  rateDisclosure: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-other-Cost-Information.pdf`,
  rateDisclosureItem: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Item.pdf`,
  rateDisclosureCash: `${CLOUDFLARE_LEGAL_URL}/Pesto-Disclosure-of-Rates-Fees-and-Costs-plus-Security-Agreement-Cash.pdf`,
  accountOpeningDisclosures: `${CLOUDFLARE_LEGAL_URL}/TILA-Disclosures-Account-Opening.pdf`,
  appStoreUrl: 'https://apps.apple.com/us/app/pesto-credit/id1669664225',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.i2c.mcpcc.pinglendv32',
  cardholderLogin: 'https://cardservicing.getpesto.com/cholder/welcome',
  nerdWalletArticle: 'https://www.nerdwallet.com/article/credit-cards/how-much-secured-card-deposit',
  rewardsTermsAndConditions: `${CLOUDFLARE_LEGAL_URL}/Pesto-Mastercard-Rewards-Program-Terms-and-Conditions.pdf`,
};
