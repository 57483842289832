import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { Wrapper1280 } from '@pesto/hubble-shared/components/UI/Wrapper/Wrapper1280';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { Link } from 'react-router-dom';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';
import { routes } from '../../../../router/routes';

import CreditFreezeImg from './assets/CreditFreeze.svg';

interface CreditFreezeStepProps {
  className?: string;
}

export const CreditFreeze = (props: CreditFreezeStepProps) => {
  const { className } = props;
  const buttonText = 'Contact support'.toUpperCase();
  const { gotoHome } = useGoRoutes();

  const rootStyle = cn(
    'flex flex-col gap-4 md:gap-8 mx-auto mt-8 mb-8 md:h-[76vh] justify-center items-center',
    className,
  );
  return (
    <Wrapper1280 className={rootStyle}>
      <Typography variant={'heroSmall'}>Credit is frozen.</Typography>
      <img src={CreditFreezeImg} className={'max-w-[350px]'} alt="credit freeze image" />
      <Typography variant={'headerSmall'}>It looks like your credit has been frozen.</Typography>
      <Typography variant={'body'} className={'max-w-[400px]'}>
        Please clear up any outstanding issues with your credit. Once you have resolved your credit freeze, please reach
        out to us and we will continue with your Pesto Mastercard&reg; Application.
      </Typography>
      <Button onClick={() => gotoHome()} buttonType={'secondary'} text={buttonText} />
      <Typography variant={'body'} className={'max-w-[400px]'}>
        If you have resolved your credit freeze, please click{' '}
        <Link className="text-action underline hover:no-underline" to={routes.app}>
          here
        </Link>{' '}
        to continue with your Pesto Mastercard&reg; Application.
      </Typography>
    </Wrapper1280>
  );
};
