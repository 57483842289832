import type { Ref } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import type { TextFieldProps } from '../FormTypes';
import { TextFieldWrapper } from '../TextFieldWrapper/TextFieldWrapper';
import { generateInputStyles } from '../inputStylesHelper';

export const TextField = forwardRef((props: TextFieldProps, ref: Ref<HTMLInputElement>) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const {
    className,
    onChange,
    defaultValue,
    label,
    caption,
    error,
    errorText,
    rightHelperText,
    required = false,
    placeholder = label,
    readOnly = false,
    textAlign = 'left',
    inputSize = 'small',
    onBlur,
    ...inputProps
  } = props;

  const inputStyle = generateInputStyles({
    className,
    isErrorVisible,
    readOnly,
    inputSize,
    textAlign,
  });

  useEffect(() => {
    setIsErrorVisible(!!error);
  }, [error]);

  return (
    <TextFieldWrapper
      errorText={errorText}
      error={isErrorVisible}
      label={label}
      caption={caption}
      required={required}
      rightHelperText={rightHelperText}
      info={!readOnly && props.info}
      textAlign={textAlign}
    >
      <input
        ref={ref}
        className={inputStyle}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={readOnly ? '' : placeholder}
        readOnly={readOnly}
        onBlur={onBlur}
        {...inputProps}
      />
    </TextFieldWrapper>
  );
});

TextField.displayName = 'TextField';
