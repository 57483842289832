import type { CurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { useOnMount } from '@pesto/hubble-shared/hooks/useOnMount';
import type { ReactNode } from 'react';

import { useGoRoutes } from '../hooks/useGoRoutes';
import { useUser } from '../hooks/useUser';

interface ApplicationProviderProps {
  children: ReactNode;
  disableAutoNavigate?: boolean;
}

export const ApplicationProvider = (props: ApplicationProviderProps) => {
  const { children, disableAutoNavigate } = props;

  const { isLoading, getUserData } = useUser();
  const { gotoStatus } = useGoRoutes();

  useOnMount(() => {
    getUserData()
      .then((user: CurrentUserQuery['currentUser'] | null) => {
        const userStatus = user?.creditApplication?.status;
        if (!disableAutoNavigate) {
          return gotoStatus(userStatus);
        }
      })
      .catch(err => {
        console.error('Failed to get the user ApplicationFlow ', err);
        return null;
      });
  });

  if (isLoading) return <LoaderSpinnerFull />;

  return <div>{children}</div>;
};
