import { LoaderSpinnerFull } from '@pesto/hubble-shared/components/LoaderSpinnerFull';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';

import { useUser } from '../../../../hooks/useUser';
import PrefilledPersonalData from '../components/PrefilledPersonalData';

interface UserInfoProps {
  onSubmit: (ssn: string) => void;
  editableSSN: boolean;
}

const UserInfo: React.FC<UserInfoProps> = ({ onSubmit, editableSSN }) => {
  const { isLoading } = useUser();

  // const { user } = useUser();

  // const offer = user?.creditApplication?.offerAmount || '';

  // const renderEstimatedCredit = (
  //   <div className="flex flex-col gap-2">
  //     <EstCreditBox value={offer} title="Your Credit Offer" />
  //   </div>
  // );

  if (isLoading) return <LoaderSpinnerFull variant={'screen'} />;
  return (
    <div className="flex flex-col gap-4">
      <Typography variant="headerLarge">Confirm your information</Typography>

      {/* {renderEstimatedCredit} */}
      <PrefilledPersonalData onSubmit={onSubmit} editableSSN={editableSSN} />
    </div>
  );
};
export default UserInfo;
