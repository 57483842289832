import {
  CurrentUserDocument,
  type CurrentUserQuery,
  useCurrentUserLazyQuery,
  useCurrentUserQuery,
} from '@pesto/hubble-shared/__generated__/graphql/api';
import { apolloClient } from '@pesto/hubble-shared/api/Apollo';
import { useAuth } from '@pesto/hubble-shared/providers/AuthProvider/AuthProvider';
import { useEffect, useState } from 'react';

export const useUser = () => {
  const { isAuthenticated } = useAuth();
  const [isLazyLoading, setIsLazyLoading] = useState(true);

  const { data } = useCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });
  const [getUser] = useCurrentUserLazyQuery({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setIsLazyLoading(false);
    },
    onCompleted: () => {
      setIsLazyLoading(false);
    },
  });

  const user = data?.currentUser;
  const isLoggedIn = !!user;
  const isLoading = isLazyLoading;
  const cacheUser = apolloClient?.cache?.readQuery<CurrentUserQuery>({ query: CurrentUserDocument })?.currentUser;

  useEffect(() => {
    if (isAuthenticated()) {
      getUser();
    } else {
      setIsLazyLoading(false);
    }
  }, [getUser, isAuthenticated]);
  const getUserData = (): Promise<CurrentUserQuery['currentUser'] | null> => {
    return new Promise((resolve, reject) => {
      if (!isAuthenticated()) {
        return reject('User is not authenticated');
      }
      getUser()
        .then(user => {
          resolve(user.data?.currentUser);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  return {
    user,
    isLoggedIn,
    isLoading,
    getUserData,
    cacheUser,
  };
};
