import type { Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import type { ReactNode } from 'react';

import { ACCESS_TOKEN_LS_KEY } from '../constants/shared-constants';

import { useHandleApolloErrors } from './hooks/useHandleApolloErrors';
import { useHandleApolloLogs } from './hooks/useHandleApolloLogs';
import { useHandleRetries } from './hooks/useHandleRetries';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_LS_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export interface ApolloClientConfig {
  path: string;
  directUri: string;
  proxyUri?: string;
}

export interface ApolloClientProps {
  children: ReactNode;
  config: ApolloClientConfig;
  isDev?: boolean;
  errorLink?: ApolloLink;
  retryLink?: ApolloLink;
  apolloLogger?: ApolloLink;
}

export let apolloClient: ApolloClient<any>;
export const ApolloProviderWrapper = (props: ApolloClientProps) => {
  const { config, isDev = false } = props;

  if (isDev) {
    loadDevMessages();
    loadErrorMessages();
  }

  const shouldUseProxy = (operation: Operation) => {
    const sensitive = operation.getContext().containsSensitiveData;
    if (sensitive && config.proxyUri) return true;
    if (sensitive) {
      console.error('Making PII request without setting proxyUri .\nSensitive fields will not be tokenized');
    }
    return false;
  };

  const directLink = authLink.concat(createHttpLink({ uri: config.directUri + config.path, fetch }));
  const proxyLink = authLink.concat(createHttpLink({ uri: config.proxyUri + config.path, fetch }));

  const apoLink = ApolloLink.split(shouldUseProxy, proxyLink, directLink);

  const defaults = {
    errorLink: useHandleApolloErrors(),
    retryLink: useHandleRetries(),
    apolloLogger: useHandleApolloLogs(),
  };

  const errorLink = props.errorLink || defaults.errorLink;
  const retryLink = props.retryLink || defaults.retryLink;
  const apolloLogger = props.apolloLogger || defaults.apolloLogger;

  const combinedLink = from([errorLink, retryLink, apolloLogger, apoLink]);

  apolloClient = new ApolloClient({
    connectToDevTools: isDev,
    link: combinedLink,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            currentUser: {
              merge(existing, incoming) {
                if (!existing) return incoming;
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  });

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
